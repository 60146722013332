// entry.client.js
import createApp from './src/app.js';

const { app, router } = createApp();

router.onReady(() => {
  updateMeta(router.currentRoute);

  router.beforeEach((to, from, next) => {
    updateMeta(to);
    next();
  });

  app.$mount('#app');
});

function updateMeta(route) {
  const meta = route.meta || {};
  const defaultDescript = `Introduce GB WhatsApp and offer Secure version of GBWhatsApp that has resolved login issues. Download and Update GBWhatsApp by AlexMODs, which has extra features like the best AI image generator. `
  const defaultKeyword = 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download'
  const defaultCanonical = 'https://gbwhatsapp.chat';

  document.title = meta.title || 'GB WhatsApp|GB WhatsApp Pro APK Download  V18.20 2024(Anti-Ban)';

  setMetaTag('description', meta.description || defaultDescript);
  setMetaTag('og:description', meta.description || defaultDescript);
  setMetaTag('keywords', meta.keywords || defaultKeyword);
  setLinkTag('canonical', meta.link?.find(link => link.rel === 'canonical')?.href || defaultCanonical);
}

function setMetaTag(name, content) {
  let element = document.querySelector(`meta[name="${name}"]`);
  if (element) {
    element.setAttribute('content', content);
  } else {
    element = document.createElement('meta');
    element.setAttribute('name', name);
    element.setAttribute('content', content);
    document.head.appendChild(element);
  }
}

function setLinkTag(rel, href) {
  let element = document.querySelector(`link[rel="${rel}"]`);
  if (element) {
    element.setAttribute('href', href);
  } else {
    element = document.createElement('link');
    element.setAttribute('rel', rel);
    element.setAttribute('href', href);
    document.head.appendChild(element);
  }
}